/* IMPORTS */
import { gsapScrollAnimation } from './animation'

// ANIMATION
gsapScrollAnimation()


// NAVBAR
$(window).on('scroll', function () {
    if (document.documentElement.clientWidth > 1199) {
        $('nav').toggleClass('scrolled', $(this).scrollTop() > 490)
        $('.navbar-image-wrapper img:first-child').toggleClass('none', $(this).scrollTop() > 490)
        $('.navbar-image-wrapper img:nth-child(2)').toggleClass('inline', $(this).scrollTop() > 490)
    }
})


// VIDEO
window.onload = function () {
    let video = document.getElementById("video-hero")
    let playButton = document.getElementById("video-pause")
    let mutedButton = document.getElementById("video-muted")

    // event listener for the play/pause button
    playButton.addEventListener("click", function () {
        if (video.paused == true) {
            video.play()
            playButton.classList.toggle("play")
        } else {
            video.pause()
            playButton.classList.toggle("play")
        }
    })
    // event listener for the mute button
    mutedButton.addEventListener("click", function () {
        if (video.muted == false) {
            video.muted = true
            mutedButton.classList.toggle("sound")
        } else {
            video.muted = false
            mutedButton.classList.toggle("sound")
        }
    })
}


$(function() {
    var hash = location.hash; // Get the hashtag
    if(hash!=""){ // See if it contain something
    console.log("remove class")
    $('#nav-products .tab-pane').removeClass('active')
    console.log("add class")
    $(hash).addClass('active')
}

    // popup immagine notizie
    $('.news-detail-image a').magnificPopup({type: 'image'});
});

$( "#reset-filters" ).on( "click", function() {
    window.location = window.location.href.split("?")[0];
});
